<template>
  <!-- 菜单管理 -->
  <div class="wrapper">
    <!-- <el-tabs v-model="menuModule" @tab-click="handleClick">
      <el-tab-pane v-for="item in MENU_MODULE" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
    </el-tabs> -->

    <div class="btn-group">
      <div></div>
      <div>
        <el-button v-show="isShowBtn('MenuAdd')" type="primary" size="mini" icon="el-icon-plus" @click="$router.push({ name: 'MenuAdd' })">添加</el-button>
      </div>
    </div>
    <el-table
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-row-style="{ height: '50px', color: '#626e7c' }"
      :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
      :cell-style="changeTr"
    >
      <el-table-column label="名称" align="center" width="250px">
        <template slot-scope="scope">{{ scope.row.menuName }}</template>
      </el-table-column>
      <el-table-column label="菜单类型" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.menuType == 1" type="success">目录</el-tag>
          <el-tag v-if="scope.row.menuType == 2" type="info">菜单</el-tag>
          <el-tag v-if="scope.row.menuType == 3" type="warning">按钮</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="路由类型" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.linkType == 1">内链</el-tag>
          <el-tag v-if="scope.row.linkType == 2" type="danger">外链</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link v-show="isShowBtn('MenuAdd')" type="primary" @click="goAdd(scope.row)">添加</el-link>
          <el-link v-show="isShowBtn('MenuEdit')" style="margin:0 10px" type="primary" @click="goEdit(scope.row)">编辑</el-link>
          <el-popconfirm title="确定删除吗" @confirm="apiDelMenu(scope.row.id)">
            <el-link v-show="isShowBtn('MenuDel')" style="margin-left:10px" slot="reference" type="danger">
              删除
            </el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getMenusTree, delMenu, getModuleList } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      total: 0
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.apiQueryMenuTree()
  },
  methods: {
    // 添加一级菜单
    goAddRootMenu() {
      this.$router.push({ name: 'MenuDetail' })
    },
    // 添加子菜单
    goAdd(row) {
      const { id, parentId } = row
      this.$router.push({
        name: 'MenuAdd',
        params: {
          menuId: id,
          parentId
        }
      })
    },
    goEdit(row) {
      const { id } = row
      this.$router.push({ name: 'MenuEdit', params: { menuId: id } })
    },
    async apiQueryMenuTree() {
      let res = await getMenusTree()
      this.list = res || []
    },

    async apiDelMenu(id) {
      console.log(id)
      // let ids = []
      // ids.push(id)
      await delMenu(id)
      this.apiQueryMenuTree()
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '23px' }
      }
    },
    handleClick(val) {
      this.apiQueryMenuTree()
    }
    // async getModuleList() {
    //   this.MENU_MODULE = await getModuleList()
    // }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  // .el-table /deep/ {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  // }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
